import {
    Box,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    IconButton,
    Modal,
    Stack,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useRef, useState } from "react";
  import HighlightOffIcon from "@mui/icons-material/HighlightOff";
  import ModalCss from "../../styles/modal.module.scss";
  import { makeStyles } from "@mui/styles"; // Changed import to use curly braces for makeStyles
  import { useDispatch } from "react-redux";
  import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
  import { showToast } from "../../toast/toast";
  import { ordersApi, uploadQuotationApi } from "../../store/orders/orders";
  import CancelIcon from '@mui/icons-material/Cancel';
  import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating unique IDs


  
  const useStyles = makeStyles((theme) => ({
    bgLightGray: {
      backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
      borderRadius: "10px",
    },
    p10: {
      padding: "10px",
    },
    p20: {
      padding: "20px",
    },
    buttonsStyle: {
      backgroundColor: "#D7EDFE",
    },
    fullWidth: {
      width: "100%",
    },
  }));
  
  const Quotation = ({ open, handleClose, subOrderId }) => {
    const classes = useStyles();
    const [feedback, setFeedback] = useState("");
    const [invoiceError, setInvoiceError] = useState(false);
    const [invoiceFile, setInvoiceFile] = useState([]);
    const [files, setFiles] = useState([]);

    const [fileError, setFileError] = useState("");
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const [fileState, setFileState] = useState([]);
  
    const handleBulkUpload = () => {
      inputRef.current.click();
    };
    
    const handleFileUpload = (event) => {
      const selectedFiles = Array.from(event.target.files);
      const validFiles = [];
      let errorMessage = "";
    
      // Check if adding the selected files exceeds the 4-file limit
      if (files.length + selectedFiles.length > 4) {
        showToast("You can only upload up to 4 files",2);
        return;
      }
    
      selectedFiles.forEach((file) => {
        const fileType = file.type;
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (fileType === 'application/pdf' && fileExtension === 'pdf') {
          validFiles.push({
            id: uuidv4(),
            file,
          });
        } else {
          errorMessage = "Only PDF files are allowed.";
        }
      });
    
      if (validFiles.length > 0) {
        setFiles((prevState) => [...prevState, ...validFiles]);
        setFileState((prevState) => [...prevState, ...validFiles]);
        setFileError("");
      } else {
        setFiles([]);
        setFileState(null);
        setFileError(errorMessage);
      }
    };
    
    // const handleFileUpload = (event) => {
    //   const selectedFiles = Array.from(event.target.files);
    //   const validFiles = [];
    //   let errorMessage = "";
    //   selectedFiles.forEach((file) => {
    //     const fileType = file.type;
    //     const fileExtension = file.name.split('.').pop().toLowerCase();
    //     if (fileType === 'application/pdf' && fileExtension === 'pdf') {
    //       validFiles.push({
    //         id: uuidv4(),
    //         file,
    //       });
    //     } else {
    //         errorMessage = "Only PDF files are allowed.";
    //     }
    // });

    // if (validFiles.length > 0) {
    //   setFiles(prevState => [...prevState, ...validFiles]);
    //   setFileState(prevState => [...prevState, ...validFiles]); 
    //   setFileError("");
    // } else {
    //   setFiles([]);
    //   setFileState(null);
    //   setFileError(errorMessage);
    // }
    //   // if (file) {
    //   //   const fileType = file.type;
    //   //   const fileExtension = file.name.split('.').pop().toLowerCase();
    //   //   if (fileType === 'application/pdf' && fileExtension === 'pdf') {
    //   //     setFileState(file);
    //   //     setFileError("");
    //   //   } else {
    //   //     setFileState(null);
    //   //     setFileError("Only PDF files are allowed.");
    //   //   }
    //   // }
    // };
  
  const handleDeleteFile = (fileId) => {
      setFiles((prevFiles) => prevFiles.filter(file => file.id !== fileId));
      setFileState((prevFiles) => prevFiles.filter(file => file.id !== fileId));
  };
  


  const [isLoading, setIsLoading] = useState(false);
    const sendQuotationToDealer = async () => {
      if (!fileState) {
        // Handle the error if no file is selected
        showToast("Please upload an Quotation",2);
        return;
      }
  
      const formData = new FormData();
      formData.append("order_id", subOrderId);
        // formData.append("utr_no", utrNo);
        // formData.append("feedback", feedback);
        // formData.append("quotation_file", fileState);
      fileState.forEach((file, index) => {
          formData.append(`quotation_files`, file.file);
      });
      setIsLoading(true);
      await dispatch(uploadQuotationApi(formData))
          .then((data) => {
            showToast(data?.payload?.message, 1);
            setTimeout(() => {
                dispatch(ordersApi());
            }, 2000);
            handleClose()
            setIsLoading(false);
            })
            
          .catch((error) => {
            setIsLoading(false);
            console.error("Error editing email template:", error);
          });
    };

    const closeTheModal = () => {
      setFiles([]);
      setInvoiceFile(null);
      setFileError("");
      setFileState("");
      handleClose();
    };
  
    return (
      <Modal open={open} onClose={() => closeTheModal()} className={ModalCss.customModal}>
            <Card className={`modal ${ModalCss.customModal_md}`}>
                <CardHeader sx={{ textAlign: "center",margin:0 }} title="Upload Quotation" />
                <Typography component="div" sx={{ textAlign: "center", marginTop: 0, marginBottom: 1 }}>
                    Please upload Quotation(s) and send them to the dealer.
                </Typography>
                <Box
                    component="div"
                    sx={{
                        maxWidth: "600px",
                        margin: "50px auto",
                        textAlign: "center",
                        maxHeight:"400px",overflowY:"auto"
                    }}
                >
                    <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
                        <IconButton onClick={() => closeTheModal()}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        justifyContent="center"
                        direction="column"
                        gap={2}
                        alignItems="center"
                        fontSize={"18px"}
                        boxSizing={"border-box"}
                        component={"div"}
                    >
                        <Box
                            component={"div"}
                            className={`${classes.bgLightGray} ${classes.borderRadius10} ${classes.p20}`}
                            boxSizing={"border-box"}
                            sx={{
                              width: "60%",}}
                        >
                            <Typography
                                component={"div"}
                                sx={{
                                    width: "100%",
                                    fontSize: "15px",
                                    color: "black",
                                    lineHeight: 1.2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "5px",
                                    height: "200px",
                                    
                                }}
                            >
                             
                                {files.length > 0 ? (
                                    
                                      <Box sx={{height:"fit-content"}}>
                                        {files.map(file => (
                                            <Tooltip title={file.name} placement="top"  className={` uploadedfilename`} >
                                              <Typography key={file.name} sx={{ fontSize: "13px" }}>
                                                {file.file.name}
                                              </Typography>
                                              
                                              <CancelIcon onClick={() => handleDeleteFile(file.id)}/>
                                              </Tooltip>
                                        ))}
                                        </Box>
                                )
                                 : (
                                    <>
                                        <FileUploadOutlinedIcon sx={{ fontSize: "80px" }} />
                                        Upload Quotation(s)
                                    </>
                                )}
                                <input
                                    type="file"
                                    ref={inputRef}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={handleFileUpload}
                                    accept="application/pdf"
                                />
                            </Typography>
                        </Box>
                        {fileError && (
                            <Typography
                                component={"div"}
                                sx={{ color: "red", fontSize: "14px", marginTop: 1 }}
                            >
                                {fileError}
                            </Typography>
                        )}
                        <Box
                            component={"div"}
                            width={"100%"}
                            marginBlockStart={0.5}
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={2}
                        >
                            <Button
                                variant="contained"
                                onClick={handleBulkUpload}
                                className="white-btn"
                            >
                                Choose files
                            </Button>
                        </Box>
                        <Box
                            component={"div"}
                            width={"100%"}
                            marginBlockStart={2}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={2}
                        >
                            <Button
                                variant="contained"
                                onClick={sendQuotationToDealer}
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    lineHeight: 1.8,
                                    boxShadow: "none",
                                    backgroundColor: "#000 !important",
                                }}
                                className={classes.buttonsStyle}
                            >
                                {isLoading ? <CircularProgress size={30} sx={{color:"white"}}/> : "Send Quotation"}
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Card>
        </Modal>
    );
  };
  
  export default Quotation;
  